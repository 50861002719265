import React, { Component, useState } from 'react';
import Switch from '@mui/material/Switch';
import AsyncStorage from 'localforage';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons';

import axios from 'axios';
import './Settings.css';

const Settings = ({formData: userData, setFormData: setUserData, userInitState}) => {
  const navigate = useNavigate();
  // console.log({userData})
  if(!userData?.logged_in){
    navigate('/');
  }
  const [formDataSettings, setFormDataSettings] = useState(userData);
  
  const isAdminAccessAllowedCurrent = formDataSettings?.is_admin_access_allowed ?? 'disabled';

  const [isAdminAccessAllowed, setisAdminAccessAllowed] = useState(isAdminAccessAllowedCurrent);

  const [isAdminAccessAllowedLoading, setisAdminAccessAllowedLoading] = useState(false);

  console.log({isAdminAccessAllowed})

  const handleLogout = async() => {
    try{
      setUserData(userInitState);
      await AsyncStorage.removeItem('user_info');
      navigate('/');
    } catch(err) {
      console.log('Error occured during logging out: ', err);
      alert('Some error occured please try again later');
    }
  }

  const toggleAdminAccessModeSwitch = async() => {
    const adminAccess = (isAdminAccessAllowed == 'enabled') ? 'disabled' : 'enabled';
    try {
      setisAdminAccessAllowedLoading(true);
      setisAdminAccessAllowed(adminAccess);
      formDataSettings.is_admin_access_allowed = adminAccess;
      const payload = {
        ...formDataSettings,
        is_admin_access_allowed: adminAccess
      };
      console.log('adminAccess change = ', JSON.stringify(payload, null,2));
      await AsyncStorage.setItem('user_info', JSON.stringify(payload));
      setFormDataSettings(payload);

      const primary_ip_address = formDataSettings.primary_server_ip_address;

      const data = {
        port: 3002 + (formDataSettings?.sno ?? 1)
      };

      const headers = {
        token: process.env.EXPO_PUBLIC_UPDATE_TOKEN
      };

      if(adminAccess == "enabled") {
        console.log(`http://${primary_ip_address}:3000/create-service`)
        const create_res = await axios.post(`http://${primary_ip_address}:3000/create-service`, data, { headers });
        console.log("create_res", create_res.data);
        const res = await axios.post(`http://${primary_ip_address}:3000/start-service`, data, { headers });
        console.log("Start RES= ", res.data);
      } else {
        console.log(`http://${primary_ip_address}:3000/stop-service`)
        const del_res = await axios.delete(`http://${primary_ip_address}:3000/stop-service`, { headers });
        console.log("Stop RES= ", del_res);
      }
    } catch(err) {
      console.log("Error occured in toggleAdminAccessModeSwitch: ", JSON.stringify(err, null, 2));
      const adminAccessError = (adminAccess == 'enabled') ? 'disabled' : 'enabled';
      setisAdminAccessAllowed(adminAccessError);
    }
    setisAdminAccessAllowedLoading(false);
  }

  return (
    <div className="settings_container">
      <div className="settings_scrollView">
        <header className="settings_header_welcome">
                Settings
        </header>
        <div style={{ marginTop: '-60vw', flex: 1, paddingHorizontal: 30 }}>
            {/* <div
              style={{
                marginTop: 40,
                marginBottom: 20,
                borderBottomColor: 'black',
                borderBottomWidth: 1,
              }}
            /> */}
            <div style={{marginTop: 50, backgroundColor: 'white', padding: 30, borderStyle: 'solid', borderWidth: 3, borderColor: 'yellow'}}>
              <p style={{flex: 1, fontSize: 16, textAlign: 'center', marginBottom: 20 }} >Allow Admin Access</p>
              <div style={{ paddingHorizontal: 20, marginTop: 10, marginLeft: 100 }}>
                <Switch
                  style={{color: 'gold'}}
                  // style={{flex: 1, alignItems: 'flex-end', marginTop: 10, marginRight: 70}}
                  key={`switch_online_mode`} 
                  onChange={() => {
                    toggleAdminAccessModeSwitch();
                  }}
                  disabled={isAdminAccessAllowedLoading}
                  value={(isAdminAccessAllowed == 'enabled') ? true : false}
                />
              </div>
            </div>
            <div style={{marginTop: 30, backgroundColor: 'white', padding: 30, borderStyle: 'solid', borderWidth: 3, borderColor: 'yellow'}}>
              <p style={{ flex: 1, fontSize: 16, textAlign: 'center', marginBottom: 20 }} >Logout</p>
              <div style={{ paddingHorizontal: 20, marginTop: 10, marginLeft: 88 }}>
                <button
                  style={{width: 90, height: 45, backgroundColor: 'lightyellow', borderColor: 'gold', borderRadius: 20}}
                  className="settings_button" 
                  onClick={handleLogout}
                > 
                  <FontAwesomeIcon icon={faRightFromBracket}/>
                </button>
              </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Settings;
