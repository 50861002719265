import React from 'react';
import { NavLink } from 'react-router-dom';

const BottomTabNavigator = ({ routes }) => {
  return (
    <nav style={{
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: '#f8f9fa',
      display: 'flex',
      justifyContent: 'space-around',
      padding: '10px 0'
    }}>
      {routes.map((route, index) => (
        <NavLink
          key={index}
          to={route.path}
          style={{
            textDecoration: 'none',
            color: 'lightblue',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
          activeStyle={{
            color: 'gold'
          }}
        >
          {route.icon}
          <span>{route.label}</span>
        </NavLink>
      ))}
    </nav>
  );
};

export default BottomTabNavigator;