import React, { Component, useState } from 'react';
import Select from 'react-select';

import AsyncStorage from 'localforage';
// import firestore from '@react-native-firebase/firestore';
import axios from 'axios';
import Alert from './Alert';

import './Register.css';

const URL = `https://smenterprisestech.in`; //`http://localhost:3000`;

const Register = ({formData: userData, setFormData: setUserData}) => {
    const [formData, setFormData] = useState(userData);

    const handleFormChange = (name, e_value) => {
      const value = e_value.target.value;

      if (name == "total_rooms") {
        if(value >= 0){
          let roomInfo = {};
          for (let i = 0; i < Number(value); i++) {
            const payload = {
              type: '',
              total_switches: '',
              switches: {},
              // ip_address: '',
              // buttons: ''
            };
            roomInfo = {
              ...roomInfo,
              [`${(i + 1)}`]: payload,
            }
          }
          console.log({ roomInfo });
          setFormData({
          ...formData,
          [name]: value,
          roomInfo,
          });
        }
      } else {
        setFormData({
          ...formData,
          [name]: value.trim()
        });
      }
    };
    
    const handleSwitchChange = (e_no_of_switches, roomNo) => {
      let roomInfo = {};
      let switches = {};
      const no_of_switches = e_no_of_switches.target.value;
      if(no_of_switches >= 0 && parseInt(no_of_switches) && parseInt(no_of_switches) > 0){
        for (let i = 0; i < Number(no_of_switches); i++) {
          const payload = {
            // type: '',
            ip_address: '',
            buttons: '',
            switch_config: '',
            online_mode: 'disabled',
            // auth_token: `${}`,
            // buttonInfo: ''
          };
          switches = {
            ...switches,
            [`switch_board_${(i + 1)}`]: payload,
          }
          roomInfo = {
            ...roomInfo,
            [roomNo]: {
              ...formData?.roomInfo[roomNo],
              total_switches: no_of_switches,
              switches
            }
          }
        }
        setFormData({
          ...formData,
          roomInfo: {
            ...formData?.roomInfo,
            [roomNo]: {
              ...formData?.roomInfo[roomNo],
              total_switches: no_of_switches,
              switches
            }
          },
        });
      } else {
        setFormData({
          ...formData,
          roomInfo: {
            ...formData?.roomInfo,
            [roomNo]: {
              ...formData?.roomInfo[roomNo],
              total_switches: '',
              switches
            }
          },
        });
      }
    }
    const handleSwitchPropertyChange = (property, e_value) => {
      // const t_value = e_value.target.value;
      const { itemValue: value, k: roomNo, i: switchNo } = e_value;
      // const buttonInfo = formData?.roomInfo[roomNo]?.switch?.[switchNo]?.buttonInfo ?? {}; 
      setFormData({
        ...formData,
        roomInfo: {
          ...formData.roomInfo,
          [roomNo]: {
            ...formData.roomInfo[roomNo],
            switches: {
              ...formData.roomInfo[roomNo].switches,
              [`switch_board_${switchNo}`]:{
                ...formData.roomInfo[roomNo]?.switches[`switch_board_${switchNo}`],
                [property]: value,
                // buttonInfo,
              } 
            }
          },
        },
      });
    };

    const handlePrimaryUnitChanges = (key, e_value) => {
      const value = e_value.target.value;
      setFormData({
        ...formData,
        [key]: value.trim(),
      });
    };

    const handleRoomTypeChange = (e_value, roomNo) => {
      const value = e_value.target.value;
      const buttonInfo = formData?.roomInfo[roomNo]?.buttonInfo ?? {}; 
      setFormData({
        ...formData,
        roomInfo: {
          ...formData.roomInfo,
          [roomNo]: {
              ...formData.roomInfo[roomNo],
              // ip_address: formData.roomInfo[roomNo]?.ip_address ?? '',
              // buttons: formData.roomInfo[roomNo]?.buttons ?? '',
              type: value.trim(),
              // buttonInfo,
          },
        },
      });
    };
    const buttonGPIOConfig = {
      button_0: '5',
      button_1: '4',
      button_2: '2',
      button_3: '0',
      button_4: '14',
      button_5: '12',
      button_6: '13'
    }
    const buttonGPIODConfig = {
      D1: '5',
      D2: '4',
      D3: '2',
      D4: '0',
      D5: '14',
      D6: '12',
      D7: '13'
    }
    const gpio = ["5","4","2","0","14","12","13"];
    const gpio_d_config = ["D1", "D2", "D3", "D4", "D5", "D6", "D7"];

    const handleRoomButtonChange = (e_value, details) => {
      const value = e_value.target.value;
      const {k: roomNo, i: switchNo} = details;
      let buttonInfo = {};
      if(value >= 0 && parseInt(value) && parseInt(value) > 0){
        for (let i = 0; i < value; i++){
          const temp = {
            [`button_${i}`]: {
              // pin: buttonGPIOConfig[`button_${i}`],
              pin: '',
              value: ''
            },
          };
          buttonInfo = {
            ...buttonInfo,
            ...temp
          }
        }
        setFormData({
          ...formData,
          roomInfo: {
            ...formData.roomInfo,
            [roomNo]: {
              ...formData.roomInfo[roomNo],
              switches: {
                ...formData.roomInfo[roomNo].switches,
                [`switch_board_${switchNo}`]:{
                  ...formData.roomInfo[roomNo].switches[`switch_board_${switchNo}`],
                  // ip_address: formData.roomInfo[roomNo]?.ip_address ?? '',
                  // type: formData.roomInfo[roomNo]?.type ?? '',
                  buttons: value,
                  buttonInfo
                },
              }
            }
          },
        });
      } else {
        setFormData({
          ...formData,
          roomInfo: {
            ...formData.roomInfo,
            [roomNo]: {
              ...formData.roomInfo[roomNo],
              switches: {
                ...formData.roomInfo[roomNo].switches,
                [`switch_board_${switchNo}`]:{
                  ...formData.roomInfo[roomNo].switches[`switch_board_${switchNo}`],
                  // ip_address: formData.roomInfo[roomNo]?.ip_address ?? '',
                  // type: formData.roomInfo[roomNo]?.type ?? '',
                  buttons: '',
                  buttonInfo
                },
              }
            }
          },
        });
      }
    };
    // const handleButtonTypeChange = (value, roomNo, switchNo, buttonNo) => {
    //   const payload = {
    //     ...formData,
    //     roomInfo: {
    //       ...formData.roomInfo,
    //       [roomNo]: {
    //         ...formData.roomInfo[roomNo],
    //         switches: {
    //           ...formData.roomInfo[roomNo].switches,
    //           [`switch_board_${switchNo}`]: {
    //             ...formData.roomInfo[roomNo].switches[`switch_board_${switchNo}`],
    //             buttonInfo: {
    //               ...formData.roomInfo[roomNo].switches[`switch_board_${switchNo}`].buttonInfo,
    //               [`button_${(buttonNo-1)}`]: {
    //                 ...formData.roomInfo[roomNo].switches[`switch_board_${switchNo}`].buttonInfo[`button_${(buttonNo-1)}`],
    //                 // pin: buttonGPIOConfig[`button_${(buttonNo-1)}`],
    //                 value
    //               }
    //           },
    //           },
    //         }
    //       },
    //     }
    //   };
    //   console.log(JSON.stringify(payload,null,2))
    //     setFormData(payload);
    // };

    const handleButtonConfigChange = (property, e_value) => {
      console.log({property, e_value})
      const { buttons, k: roomNo, i: switchNo, j: buttonNo } = e_value;
      const value = buttons.target.value;
      const payload = {
        ...formData,
        roomInfo: {
          ...formData.roomInfo,
          [roomNo]: {
            ...formData.roomInfo[roomNo],
            switches: {
              ...formData.roomInfo[roomNo].switches,
              [`switch_board_${switchNo}`]: {
                ...formData.roomInfo[roomNo].switches[`switch_board_${switchNo}`],
                buttonInfo: {
                  ...formData.roomInfo[roomNo].switches[`switch_board_${switchNo}`].buttonInfo,
                  [`button_${(buttonNo-1)}`]: {
                    ...formData.roomInfo[roomNo].switches[`switch_board_${switchNo}`].buttonInfo[`button_${(buttonNo-1)}`],
                    [property]: value.trim(),
                    // value
                  }
              },
              },
            }
          },
        }
      };
      console.log(JSON.stringify(payload,null,2))
        setFormData(payload);
    };

    const handleFormSubmit = async () => {
      <Alert
        title="Confirm"
        message= "You want to proceed with completing registering?"
        buttons={[
          {
            text: "Cancel",
            onPress: () => {},
            // style: "cancel",
          },
          {
            text: "Confirm",
            onPress: async() => {
              if(!formData.email || !formData.contact || !formData.password || !formData.confirm_password || !formData.fullname || !formData.roomInfo || formData.roomInfo == "" || !formData.total_rooms){
                alert('Kindly provide all the details!');
              } else if(!formData.email.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)){
                alert('Kindly provide a valid email address');
              } else if(formData.password != formData.confirm_password){
                alert('password confirmation failed: password does not match!');
              } else {
                const payload = {
                  ...formData,
                  logged_in: true
                };
                // const checkUser = await firestore().collection('users').where('email','==',formData.email).get();
                const check = undefined;
                if(check) {
                  //Note: Remote below lines after testing and only use self server
                  alert('User already exists with this email!');
                  //Note: checking if user exists in own server if not then create it.
                  // check for login and same email
                  //this is for login
                  let isLoggedIn = false;
                  try {
                    const check_prod_login_res = await axios.get(`${URL}/users/me`);
                    if(check_prod_login_res.data) {
                      alert("You are already loggedin kindly log out first");
                      isLoggedIn = true;
                    } 
                  } catch(err){
                    console.log(err.response);
                    isLoggedIn = false;
                  }
                  if(!isLoggedIn){
                    let isEmailExists = false;
                    try {
                      const check_prod_email_res = await axios.get(`${URL}/users/${formData.email}`);
                      if(check_prod_email_res.data){
                        alert("User already exists with this email!");
                        isEmailExists = true;
                      }
                    } catch(err){
                      console.log(err.response);
                      isEmailExists = false;
                    }
                    if(!isEmailExists) {
                      try {
                        const register_user_res = await axios.post(`${URL}/users`, { ...formData });
                        payload.jwt_token = register_user_res.data.token;
                        await AsyncStorage.setItem('user_info', JSON.stringify(payload));
                        setUserData(payload);
                      } catch(err){
                        console.log("Error occured while registering the user in server", err.response);
                        alert("Could not register due to some error, kindly try again later");
                      }
                    }
                  }
                } else {
                  // await firestore().collection('users').add({
                  //   email: formData.email,
                  //   contact: formData.contact,
                  //   password: formData.password,
                  //   gender: formData.gender,
                  //   fullname: formData.fullname,
                  //   address: formData.address,
                  //   roomInfo: JSON.stringify(formData.roomInfo),
                  //   rooms: formData.total_rooms
                  // });
                  let isLoggedIn = false;
                  try {
                    const check_prod_login_res = await axios.get(`${URL}/users/me`);
                    if(check_prod_login_res.data) {
                      alert("You are already loggedin kindly log out first");
                      isLoggedIn = true;
                    } 
                  } catch(err){
                    console.log(err.response);
                    isLoggedIn = false;
                  }
                  if(!isLoggedIn){
                    let isEmailExists = false;
                    try {
                      const check_prod_email_res = await axios.get(`${URL}/users/${formData.email}`);
                      if(check_prod_email_res.data){
                        alert("User already exists with this email!");
                        isEmailExists = true;
                      }
                    } catch(err){
                      console.log(err.response);
                      isEmailExists = false;
                    }
                    if(!isEmailExists) {
                      try {
                        const register_user_res = await axios.post(`${URL}/users`, { ...formData });
                        payload.jwt_token = register_user_res.data.token;
                        await AsyncStorage.setItem('user_info', JSON.stringify(payload));
                        setUserData(payload);
                      } catch(err){
                        console.log("Error occured while registering the user in server", err.response);
                        alert("Could not register due to some error, kindly try again later");
                      }
                    }
                  }
                  // await AsyncStorage.setItem('user_info', JSON.stringify(payload));
                  // setUserData(payload);
                }
              }
            },
            // style: "cancel",
          },
        ]}
      />;
    };
    const button_types = ['Light', 'Fan', 'TV', 'AC', 'Fridge'];
    console.log(JSON.stringify(formData?.roomInfo, null, 2))
    return (
      <div className="register_container">
        <div className="register_scrollView">
          <header className="register_welcome">
              Register
          </header>
          <input
              color="black"
              placeholder="Enter your name"
              value={formData?.fullname ?? ""}
              onChange={(fullname) => {
                  handleFormChange('fullname', fullname)
              }}
              className="register_input"
          />
          <input
              color="black"
              placeholder="Enter contact number"
              value={formData?.contact ?? ""}
              onChange={(contact) => {
                  handleFormChange('contact', contact)
              }}
              className="register_input"
              type="number"
          />
          <input
              color="black"
              placeholder="Enter your email address"
              value={formData?.email ?? ""}
              onChange={(email) => {
                  handleFormChange('email', email)
              }}
              className="register_input"
              type="default"
          />
          <input
              color="black"
              placeholder="Enter your password"
              value={formData?.password ?? ""}
              onChange={(password) => {
                  handleFormChange('password', password)
              }}
              className="register_input"
              type="password"
          />
          <input
              color="black"
              placeholder="Confirm your password"
              value={formData?.confirm_password ?? ""}
              onChange={(confirm_password) => {
                  handleFormChange('confirm_password', confirm_password)
              }}
              // onEndEditing={(e) => {
              //   setFormData({ ...formData, fullname: e.nativeEvent.text});
              // }}
              className="register_input"
              type="password"
          />
          {/* <View style={styles.picker}>
          <Picker
              selectedValue={formData.gender}
              onValueChange={(itemValue, itemIndex) =>
                  handleFormChange('gender', itemValue)
              }
              placeholder="Select your gender"
              color="black"
              style={{ color: 'black' }}
          >
              <Picker.Item label="Kindly Select your gender" value="" />
              <Picker.Item label="Male" value="male" />
              <Picker.Item label="Female" value="female" />
              <Picker.Item label="Other" value="other" />
          </Picker>
          </View> */}
          <input
              color="black"
              placeholder="Enter your Address"
              value={formData?.address ?? ""}
              onChange={(address) => {
                  handleFormChange('address', address)
              }}
              className="register_input"
              type="default"
          />
          <input
              color="black"
              key={`primary_server_ip_address`}
              placeholder={`Primary Unit IP Address`}
              value={formData?.primary_server_ip_address ?? ''}
              onChange={(ip_address) => {
                  handlePrimaryUnitChanges("primary_server_ip_address", ip_address)
              }}
              className="register_input"
              type="default"
          />
          <input
              color="black"
              key={`primary_server_dns`}
              placeholder={`Primary Unit DNS`}
              value={formData?.primary_server_dns ?? ''}
              onChange={(dns) => {
                  handlePrimaryUnitChanges("primary_server_dns", dns)
              }}
              className="register_input"
              type="default"
          />
          <input
              color="black"
              placeholder="How many rooms you have"
              value={formData?.total_rooms ? `${formData.total_rooms}` : ''}
              onChange={(rooms) => {
                  handleFormChange('total_rooms', rooms)
              }}
              className="register_input"
              type="number"
          />
          {/* { formData.roomInfo != '' && Object.keys(formData.roomInfo).length > 0 && 
            <p> Room Info </p>
          } */}
          { formData?.roomInfo != '' && Object.keys(formData?.roomInfo).length > 0 && Object.keys(formData?.roomInfo).map((room, k) => (
              <div key={`room_${k}_view`}>
               <p 
                  color="black" 
                  key={`room_${k}_view_text`} 
                  className="registerRoomLabel" 
                > { `Room ${k+1}`} 
                  </p>
                <input
                  color="black"
                  key={`room_${k}_type`}
                  placeholder={`Type`}
                  value={formData?.roomInfo[(k + 1)]?.type ?? ''}
                  onChange={type => handleRoomTypeChange(type, (k + 1))
                  }
                  className="register_input"
                  type="default"
                />
                <input
                  color="black"
                  key={`room_${k}_switches`}
                  placeholder={`No of Switch Boards`}
                  value={formData?.roomInfo[(k + 1)]?.total_switches ?? ''}
                  onChange={(switches) => {
                      handleSwitchChange(switches, (k+1))
                  }}
                  className="register_input"
                  type="number"
                />
              {/* { formData.roomInfo[(k + 1)]?.total_switches != null && formData.roomInfo[(k + 1)]?.total_switches != "" && formData.roomInfo[(k + 1)]?.total_switches != '0' &&
                <p> { `Room ${k+1} Switch Info` } </p>
              } */}
              { formData?.roomInfo?.[(k + 1)]?.total_switches != null && formData?.roomInfo?.[(k + 1)]?.total_switches != "" && formData?.roomInfo[(k + 1)]?.total_switches != '0' && [...Array(parseInt(formData?.roomInfo[(k + 1)]?.total_switches))].map((switches, i) => (
                <div key={`room_${k}_switch_${i}_view`}>
                  <p 
                    color="black" 
                    key={`room_${k}_switch_view_text`} 
                    className="registerSwitchLabel"> 
                    { `Room ${k+1} Switch ${i+1} Info` } 
                  </p>
                  {/* <input
                      color="black"
                      key={`room_${k}_switch_${i}_ip_address`}
                      placeholder={`IP Address`}
                      value={formData?.roomInfo[(k + 1)]?.switches[`switch_board_${(i + 1)}`]?.ip_address ?? ''}
                      onChange={(ip_address) => {
                          handleSwitchPropertyChange('ip_address', ip_address, k+1, i+1)
                      }}
                      className="register_input"
                      type="default"
                  /> */}
                  <div className="registerPicker">
                  <Select
                      value={formData?.roomInfo[(k + 1)]?.switches[`switch_board_${(i + 1)}`]?.switch_config ?? ''}
                      onChange={(itemValue, itemIndex) =>
                        handleSwitchPropertyChange('switch_config', {itemValue, k: k+1, i: i+1})
                      }
                      placeholder="Select the switch config"
                      color="black"
                      style={{ color: 'black' }}
                      options={[
                        {label: "Kindly Select Switch Config", value: "" },
                        {label: "Standard", value: "standard" },
                        {label: "Reverse", value: "reverse" }
                      ]}
                  />
                  </div>
                  <input
                      color="black"
                      key={`room_${k}_switch_${i}_buttons`}
                      placeholder={`No of Switches`}
                      value={formData?.roomInfo[(k + 1)]?.switches[`switch_board_${(i + 1)}`]?.buttons ?? ''}
                      onChange={(buttons) => {
                          handleRoomButtonChange(buttons, {k: k+1, i: i+1})
                      }}
                      className="register_input"
                      type="default"
                  />
                  { formData?.roomInfo?.[(k + 1)]?.switches[`switch_board_${(i + 1)}`]?.buttons != null && formData?.roomInfo?.[(k + 1)]?.switches[`switch_board_${(i + 1)}`]?.buttons != "" && formData?.roomInfo[(k + 1)]?.switches[`switch_board_${(i + 1)}`]?.buttons != '0' &&
                    <p 
                      color="black" 
                      key={`room_${k}_view_switch_${i+1}_text`} 
                      className="registerButtonsLabel"> 
                        { `Switches Info` } 
                    </p>
                  }
                  { formData?.roomInfo?.[(k + 1)]?.switches[`switch_board_${(i + 1)}`]?.buttons != null && formData?.roomInfo[(k + 1)]?.switches[`switch_board_${(i + 1)}`]?.buttons != "" && formData?.roomInfo[(k + 1)]?.switches[`switch_board_${(i + 1)}`]?.buttons != '0' && [...Array(parseInt(formData?.roomInfo[(k + 1)]?.switches[`switch_board_${(i + 1)}`]?.buttons))].map((button, j) => (
                    <div key={`room_${(k+1)}_switch_${(i+1)}_${button}_no_${j}_container`}>
                      <p 
                        color="black" 
                        key={`room_${(k+1)}_switch_${(i+1)}_${button}_no_${j}_container_text`} 
                        className="registerButtonLabel"> 
                        { `Switch: ${(j+1)}` } 
                      </p>
                      <input
                          color="black"
                          key={`room_${(k+1)}_switch_${(i+1)}_${button}_no_${j}_type`}
                          placeholder={`Name`}
                          value={formData?.roomInfo?.[(k + 1)]?.switches[`switch_board_${(i + 1)}`]?.buttonInfo[`button_${j}`]?.value ?? ''}
                          onChange={(buttons) => {
                            handleButtonConfigChange('value', {buttons, k: (k+1), i: (i+1), j: (j+1)})
                          }}
                          className="register_input"
                          type="default"
                      />
                      <input
                          color="black"
                          key={`room_${(k+1)}_switch_${(i+1)}_${button}_no_${j}`}
                          placeholder={`IOT Device Pin`}
                          value={formData?.roomInfo?.[(k + 1)]?.switches[`switch_board_${(i + 1)}`]?.buttonInfo[`button_${j}`]?.pin ?? ''}
                          onChange={(buttons) => {
                            handleButtonConfigChange('pin', {buttons, k: (k+1), i: (i+1), j: (j+1)})
                          }}
                          className="register_input"
                          type="default"
                      />
                    {/* 
                      <div key={`room_${(k+1)}_switch_${(i+1)}_${button}_no_${j}_picker`} style={styles.picker}>
                        <Picker
                          key={`room_${(k+1)}_switch_${(i+1)}_${button}_no_${j}`}
                          selectedValue={formData.roomInfo[(k + 1)]?.switches[`switch_board_${(i + 1)}`]?.buttonInfo[`button_${j}`]?.value ?? ''}
                          onValueChange={(itemValue, itemIndex) =>
                              handleButtonDConfigTypeChange(itemValue, (k+1), (i+1), (j+1))
                          }
                          style={{ color: 'black' }}               
                          // style={styles.picker}
                        >
                          {/* <p 
                            color="black" 
                            key={`room_${(k+1)}_switch_${(i+1)}_${button}_no_${j}_value`}> 
                            {j} 
                          </p> }
                          <Picker.Item key={`room_${k+1}_switch_${i+1}_default_picker`} label="Kindly Select the NodeMCU pin for this button" value="" />
                          { gpio_d_config.map((type, l) => (
                              <Picker.Item label={type} key={`room_${k+1}_switch_${i+1}_${type}_button_${j}_type_${l}`} value={type} />
                            )
                          )}
                        </Picker>
                      </div> */
                    }
                    </div>
                  ))}
                </div>
              ))}
            </div>
          ))}
          <button
              className="register_button"
              onClick={handleFormSubmit}
          >
          <p className="register_buttonText">Submit</p>
          </button>
        </div>
      </div>
    )
}

export default Register;

