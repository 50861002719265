import React, { Component, useState } from 'react';
import AsyncStorage from 'localforage';
import { useNavigate } from 'react-router-dom';
// import firestore from '@react-native-firebase/firestore';

import axios from 'axios';
import './Home.css';

const URL = `https://smenterprisestech.in`;

const Login = ({formData: userData, setFormData: setUserData, userInitState}) => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  
  const handleFormSubmit = (name, value) => {
    const payload = {
      ...formData,
      [name]: value.target.value
    };
    setFormData(payload);
  }; 
  //TODO: add offline logic here
  //Note: change logic here to query the new server and do the login there and store the jwt token in localstorage
  const handleLogin = async() => {
    try{
      let isEmailExists = false;
      try {
        const check_prod_email_res = await axios.get(`${URL}/users/${formData.email}`);
        if(check_prod_email_res.data){  
          isEmailExists = true;
        }
        console.log({check_prod_email_res})
      } catch(err){
        console.log("ERROR:", err);
        alert("Some error occured while login, kindly try again later");
        isEmailExists = false;
      }
      if(isEmailExists) {
        try {
          const register_user_res = await axios.post(`${URL}/users/login`, { email: formData.email, password: formData.password });
          const user_details = register_user_res.data.user;
          const payload = {
            ...userInitState,
            ...user_details,
            roomInfo: user_details.roomInfo,
            jwt_token: register_user_res.data.token,
            logged_in: true
          };
          console.log({payload})
          await AsyncStorage.setItem('user_info', JSON.stringify(payload));
          setUserData(payload);
          navigate('/controller')
        } catch(err){
          console.log("Error occured while Logging the user in server", err);
          alert("Could not register due to some error, kindly try again later");
        }
      } else {
        alert('Account does not exists with this email!');
      }
      //Note: 
      // can only test with user who is not on firebase db
      // create a new user and see if online_mode is coming correctly in register screen or not
      // see if the user is getting registered in server or not that too with correct values
      // see if correct value is getting stored in async storage based on logs in controller
      // see if correct layout of controller is rendered or not
      // see if online_mode toggle is present in controller or not
      // see if switching the toggle sends request to server or not
      // see if the request is being sent and updates are made in the db.
      // check if arduino behaves the correct way in both modes
      // remove the firebase lines or comment them out
    } catch(err) {
      console.log('Error in login: ', err);
      alert('Some error occured please try again later');
    }
  }

  return (
    <div className="container">
      <div className="scrollView">
        {/* <Text style={styles.welcome}>
            Login
        </Text> */}
        <input
          placeholder="Enter your email"
          color='black'
          value={formData.email}
          onChange={(email) => {
              handleFormSubmit('email', email)
          }}
          className="input"
        />
        <input
          placeholder="Enter your password"
          color='black'
          value={formData.password}
          onChange={(password) => {
              handleFormSubmit('password', password)
          }}
          className="input"
          type="password"
        />
        <button
            className="button"
            onClick={handleLogin}
        >
        <p className="buttonText">Login</p>
        </button>
      </div>
    </div>
  )
}

export default Login;

