import React, { Component, useState, useEffect, useContext } from 'react';

import ClipLoader from "react-spinners/ClipLoader";

import Switch from '@mui/material/Switch';

import Select from 'react-select';

import axios from 'axios';
import AsyncStorage from 'localforage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { UserContext } from '../context/userContext';
import { useNavigate } from 'react-router-dom';

// import { useIsFocused } from '@react-navigation/native';
import { v4 as uuid } from 'uuid';

import LightOn from '../assets/devices/light_on.jpeg';
import LightOff from '../assets/devices/light_off.jpeg';
import FanOn from '../assets/devices/fan_on.jpeg';
import FanOff from '../assets/devices/fan_off.jpeg';
import TVOn from '../assets/devices/tv_on.jpg';
import TVOff from '../assets/devices/tv_off.jpeg';
import ACOn from '../assets/devices/ac_on.jpg';
import ACOff from '../assets/devices/ac_off.jpeg';
import SwitchOn from '../assets/devices/switch_on.jpg';
import SwitchOff from '../assets/devices/switch_off.jpg';

import './ControllerMQT.css';

// import { WebView } from 'react-native-webview';
// import Constants from 'expo-constants';

// import { io } from 'socket.io-client';

import * as Paho from 'paho-mqtt';

const Controller = ({formData: userDetails, setFormData: setUserDetails}) => {
  const navigate = useNavigate();
    const isFocused = true; //useIsFocused();
    console.log({isFocused});
    if(!userDetails.logged_in){
      navigate('/');
    }
    const userContextData = useContext(UserContext);
    console.log({userContextData})
    const [formData, setFormData] = useState(userDetails);
    console.log({formData})
    const [roomSelected, setRoomSelected] = useState(formData?.room_selected ?? '1');
    const [timeOut, setTime] = useState(undefined);
    const [switchSelected, setSwitchSelected] = useState(formData?.roomInfo[roomSelected]?.switch_selected ?? '1');
    const [buttonsInfo, setButtonsInfo] = useState(formData?.roomInfo[roomSelected]?.switches[`switch_board_${switchSelected}`]?.buttonInfo ?? {} );
    // const [connected, setConnected] = useState(undefined);
    
    const [connected, setConnected] = useState(false);
    const [lastPong, setLastPong] = useState(null);
    const [clientState, setClientState] = useState(undefined);
    const [socket, setSocket] = useState(undefined);
    const [client, setClient] = useState(null);
    const [reconnect, setIsReconnect] = useState(false);
    // Encode a string using a simple Caesar cipher
    const encodeString = (inputString, shift) => {
      let encodedString = "";
      for (let i = 0; i < inputString.length; i++) {
          let charCode = inputString.charCodeAt(i);
          if (charCode >= 65 && charCode <= 90) {
              encodedString += String.fromCharCode(((charCode - 65 + shift) % 26) + 65); // uppercase letters
          } else if (charCode >= 97 && charCode <= 122) {
              encodedString += String.fromCharCode(((charCode - 97 + shift) % 26) + 97); // lowercase letters
          } else {
              encodedString += inputString.charAt(i); // non-alphabetic characters
          }
      }
      return encodedString;
    }

    // Decode an encoded string back to its original form
    const decodeString = (encodedString, shift) => {
      return encodeString(encodedString, (26 - shift) % 26);
    }

    // Encode a JSON string using a simple Caesar cipher
    function encodeJSON(data, shift) {
      let encodedObject = {};
      for (let key in data) {
        encodedObject[encodeString(key, shift)] = encodeString(data[key], shift);
      }
      console.log({encodedObject})
      return JSON.stringify(encodedObject);
    }

    // Decode an encoded JSON string back to its original form
    function decodeJSON(encodedJSON, shift) {
      let encodedObject = JSON.parse(encodedJSON);
      let decodedObject = {};
      for (let key in encodedObject) {
        const decoded_key = decodeString(key, shift);
        if(decoded_key == "buttons_status"){
          decodedObject[decoded_key] = encodedObject[key];
        } else {
          decodedObject[decoded_key] = decodeString(encodedObject[key], shift);
        }
      }
      return decodedObject;
    }

    //Have to set this in localStorage also.
    const onlineMode = formData?.roomInfo?.[roomSelected]?.switches?.[`switch_board_${switchSelected}`]?.online_mode ?? false;
    console.log("123=", formData?.roomInfo?.[roomSelected]?.switches?.[`switch_board_${switchSelected}`])
    const isDeviceModeEnabledState = (onlineMode && (onlineMode == "enabled")) ? true : false;
    const [isDeviceModeEnabled, setIsDeviceModeEnabled] = useState(isDeviceModeEnabledState);
    console.log("isDeviceModeEnabledState: ", isDeviceModeEnabledState);

    const send_signal_channel = "device_" + formData.email + "_" + roomSelected + "_" + switchSelected;
    // const client_id = uuid.v4();//"device_" + formData.email + "_" + roomSelected + "_" + switchSelected + "mobile";
    const device_token = formData.roomInfo[roomSelected]?.switches[`switch_board_${switchSelected}`]?.token ?? send_signal_channel;
    const receive_stats_channel = `${formData.email}_room_${roomSelected}_switch_${switchSelected}_controller`;
    const receive_stats_channel_disconnect = `${receive_stats_channel}_disconnected`;
    const auth_token = `sm_enterprises_${formData.email}_bbsr_module`;
    
    // const primary_server_dns_str = formData?.primary_server_dns ?? 'tcp://smenterprisestech.in:9001';
    // console.log({primary_server_dns_str, isDeviceModeEnabled})
    // const primary_server_dns = primary_server_dns_str ? primary_server_dns_str?.split(":")?.[1]?.split('//')?.[1] : ''
    // const primary_server_ip_address = formData?.primary_server_ip_address ?? '';
    // const host_name = isDeviceModeEnabled ? primary_server_dns : primary_server_ip_address;
    // const host_port = (isDeviceModeEnabled) ? (Number(primary_server_dns_str?.split(":")?.[2] ?? 9001)) : 9001;
    // const client_id = uuid.v4();
    // console.log({host_name, host_port})
    // console.log({client_id})

    // const healthCheckSeconds = 5;
    // // const SomeComponent = ()=>{
    // const [client] = React.useState(new Paho.Client(host_name, host_port, client_id));
    
    // const connectClient = React.useCallback(()=>{
    //   client.onMessageArrived = (message) => {
    //     console.log('onMessageArrived:', message);
    //     let state = {};
    //     console.log("BEFORE DECODING", JSON.stringify(message.payloadString));
    //     const data = decodeJSON(message.payloadString, 3);
    //     console.log("AFTER DECODING", JSON.stringify(data));
    //     console.log("TYPE", typeof(data))
    //     if(data.test) return;
    //     if(!(data.auth_token_for_user && data.auth_token_for_user == auth_token)) return;
    //     let initButtonsInfo = {
    //       ...formData.roomInfo[roomSelected]?.switches[`switch_board_${switchSelected}`]?.buttonInfo
    //     };
    //     console.log({initButtonsInfo})
    //     Object.keys(initButtonsInfo).map((buttonNo)=>{
    //       const buttonInfo = {
    //         [buttonNo]: {
    //           type: initButtonsInfo[buttonNo].value,
    //           pin: initButtonsInfo[buttonNo].pin,
    //           status: ''
    //         }
    //       }
    //       initButtonsInfo = {
    //         ...initButtonsInfo,
    //         ...buttonInfo
    //       }
    //     });
    //     const buttons_status = data?.buttons_status ?? {};
    //     console.log("STATE", buttons_status)
    //     Object.keys(initButtonsInfo).map((buttonNo)=>{
    //       console.log(initButtonsInfo[buttonNo].pin)
    //       console.log(buttons_status[initButtonsInfo[buttonNo].pin])
    //       const buttonInfo = {
    //         [buttonNo]: {
    //           type: initButtonsInfo[buttonNo].type,
    //           pin: initButtonsInfo[buttonNo].pin,
    //           status: (buttons_status[initButtonsInfo[buttonNo].pin] ? buttons_status[initButtonsInfo[buttonNo].pin] : "0")
    //         }
    //       };
    //       state = {
    //         ...state,
    //         ...buttonInfo
    //       }
    //     });
    //     console.log("[roomControl] DATA Before saving: ", formData.room_selected, state)
    //     setConnected(true);
    //     setButtonsInfo(prevState => ({
    //       ...prevState,
    //       ...state
    //     }));
    //   };
    //   client.connect({
    //     userName: process.env.EXPO_PUBLIC_MQTT_USERNAME,
    //     password: process.env.EXPO_PUBLIC_MQTT_PWD,
    //     onSuccess() {
    //       console.log('Connection established');
    //       // alert('Connection established');
    //       // setConnected(true);
    //       let initButtonsInfo = {
    //         ...formData.roomInfo[roomSelected]?.switches[`switch_board_${switchSelected}`]?.buttonInfo
    //       };
    //       Object.keys(initButtonsInfo).map((buttonNo)=>{
    //         const buttonInfo = {
    //           [buttonNo]: {
    //             type: initButtonsInfo[buttonNo].value,
    //             pin: initButtonsInfo[buttonNo].pin,
    //             status: ''
    //           }
    //         }
    //         initButtonsInfo = {
    //           ...initButtonsInfo,
    //           ...buttonInfo
    //         }
    //       });   

    //       client.subscribe(receive_stats_channel);
    //       // newClient.subscribe(receive_stats_channel_disconnect);
    //       const data = {
    //         type: "fetch",
    //         auth_token_verification: auth_token
    //       };
    //       console.log("BEFORE ENCODING", data);
    //       const msg = encodeJSON(data, 3);
    //       console.log("After ENCODING", msg);
    //       message = new Paho.Message(msg);
    //       message.destinationName = send_signal_channel;
    //       client.send(message);
    //     },
    //   })
    // }, [client, roomSelected, isDeviceModeEnabled, switchSelected]);

    // useEffect(()=>{
    //   let continousFailures = 0;
      
    //   connectClient();
      
    //   const healthCheck  = setTimeout(()=>{
    //     // if you have been offline for some time reconnect
    //     if (continousFailures === 2) {
    //       connectClient();
    //       continousFailures = 0;
    //       return;
    //     }
    //     // if your client is connected reset the count of continous failures
    //     if (client.isConnected()) {
    //       continousFailures = 0;
    //     } else {
    //       continousFailures++;
    //     }
    //   }, healthCheckSeconds *1000 )
      
    //   return ()=>{
    //     clearInterval(healthCheck);
    //     // do some cleanup of all your callbacks or they may trigger unwanted events or rerenders if they change state
    //     // client.onMessageArrived = ()=>{}
    //     if(client.isConnected()){
    //         client.disconnect()
    //     }
    //   }
    // }, [client])

    useEffect(() => {
      console.log("YESSS");
      // alert("YESSS");
      setConnected(false);
      if(timeOut){
        clearTimeout(timeOut);
      }
      try {
        try {
          if(client){
            client.disconnect();
            setClient(null);
            // setConnected(false);
          }
        } catch(err2) {
          console.log({err2})
        }

      const send_signal_channel = "device_" + formData.email + "_" + roomSelected + "_" + switchSelected;
      const device_token = formData.roomInfo[roomSelected]?.switches[`switch_board_${switchSelected}`]?.token ?? send_signal_channel;
      const receive_stats_channel = `${formData.email}_room_${roomSelected}_switch_${switchSelected}_controller`;
      const receive_stats_channel_disconnect = `${receive_stats_channel}_disconnected`;
      const auth_token = `sm_enterprises_${formData.email}_bbsr_module`;
      
      const primary_server_dns_str = formData?.primary_server_dns ?? 'tcp://smenterprisestech.in:9001';
      console.log({primary_server_dns_str, isDeviceModeEnabled})
      const primary_server_dns = primary_server_dns_str ? primary_server_dns_str?.split(":")?.[1]?.split('//')?.[1] : ''
      const primary_server_ip_address = formData?.primary_server_ip_address ?? '';
      const host_name = isDeviceModeEnabled ? primary_server_dns : primary_server_ip_address;
      const host_port = (isDeviceModeEnabled) ? (Number(primary_server_dns_str?.split(":")?.[2] ?? 9001)) : 9001;
      const client_id = uuid();
      console.log({host_name, host_port})
      console.log({client_id})
      const newClient = new Paho.Client(host_name, host_port, client_id);
      // try {
      //   newClient.disconnect();
      // } catch(e) {
      //   console.log({e})
      // } 
      console.log({host_name, host_port, newClient})
      // alert(host_name);
      setClient(newClient);
      if(isFocused){
        newClient.connect({
        userName: process.env.REACT_APP_MQTT_USERNAME,
        password: process.env.REACT_APP_MQTT_PWD, 
        reconnect: true,
        useSSL: true,
        keepAliveInterval: 60,
        timeout: 2,
        onSuccess() {
          console.log('Connection established');
          // alert('Connection established');
          // setConnected(true);
          let initButtonsInfo = {
            ...formData.roomInfo[roomSelected]?.switches[`switch_board_${switchSelected}`]?.buttonInfo
          };
          Object.keys(initButtonsInfo).map((buttonNo)=>{
            const buttonInfo = {
              [buttonNo]: {
                type: initButtonsInfo[buttonNo].value,
                pin: initButtonsInfo[buttonNo].pin,
                status: ''
              }
            }
            initButtonsInfo = {
              ...initButtonsInfo,
              ...buttonInfo
            }
          });   

          newClient.subscribe(receive_stats_channel);
          // newClient.subscribe(receive_stats_channel_disconnect);
          const data = {
            type: "fetch",
            auth_token_verification: auth_token
          };
          console.log("BEFORE ENCODING", data);
          const msg = encodeJSON(data, 3);
          console.log("After ENCODING", msg);
          let message = new Paho.Message(msg);
          message.destinationName = send_signal_channel;
          newClient.send(message);
        },
        onFailure(error) {
          // alert('ERROR in connecting');
          // alert(JSON.stringify(error));
          console.error("ERROR in connecting:", error);
          // console.log({connected, timeOut})
          // if(timeOut){
          //   clearTimeout(timeOut);
          // }
          // if(!connected){
          //   const timeVar = setTimeout(()=>{
          //     setIsReconnect(!reconnect);
          //   }, 2000);
          //   setTime(timeVar);
          // }
          // setIsReconnect(!reconnect);
          // setClient(null);
          // setConnected(false);
        },
      });

      newClient.onConnectionLost = (responseObject) => {
        // alert('ERROR in connection');
        // alert(JSON.stringify(responseObject));
        console.log("ERROR In connection", responseObject);
        // console.log({connected, timeOut})
        // if(timeOut){
        //   clearTimeout(timeOut);
        // }
        // if(!connected){
        //   const timeVar = setTimeout(()=>{
        //     setIsReconnect(!reconnect);
        //   }, 2000);
        //   setTime(timeVar);
        // }
        // setClient(null);
        // setConnected(false);
        if (responseObject.errorCode !== 0) { 
          console.error('onConnectionLost:' + responseObject.errorMessage);
        }
      };
      
      newClient.onMessageArrived = (message) => {
        console.log('onMessageArrived:', message);
        // if((message.topic == `${receive_stats_channel}_disconnected`)){
        //   setConnected(false);
        //   setClient(null);
        //   return;
        // }
        // if(message.topic != receive_stats_channel){
        //   return;
        // }
        let state = {};
        // setConnected(true);
        // console.log("TYPE", typeof(message.payloadString))
        console.log("BEFORE DECODING", JSON.stringify(message.payloadString));
        const data = decodeJSON(message.payloadString, 3);
        console.log("AFTER DECODING", JSON.stringify(data));
        // const data = JSON.parse(data_str);//JSON.parse(msg.data);
        // console.log(Object.keys(data?.buttons_status))
        console.log("TYPE", typeof(data))
        if(data.test) return;
        if(!(data.auth_token_for_user && data.auth_token_for_user == auth_token)) return;
        // console.log(data);
        let initButtonsInfo = {
          ...formData.roomInfo[roomSelected]?.switches[`switch_board_${switchSelected}`]?.buttonInfo
        };
        console.log({initButtonsInfo})
        Object.keys(initButtonsInfo).map((buttonNo)=>{
          const buttonInfo = {
            [buttonNo]: {
              type: initButtonsInfo[buttonNo].value,
              pin: initButtonsInfo[buttonNo].pin,
              status: ''
            }
          }
          initButtonsInfo = {
            ...initButtonsInfo,
            ...buttonInfo
          }
        });
        // const switchInfo = data?.switchInfo ?? {};
        const buttons_status = data?.buttons_status ?? {};//switchInfo.buttonInfo || {};
        console.log("STATE", buttons_status)
          Object.keys(initButtonsInfo).map((buttonNo)=>{
            // const button_no = buttonNo.split("_")[1];
            // const button_no = buttonInfo[buttonNo].pin;
            console.log(initButtonsInfo[buttonNo].pin)
            console.log(buttons_status[initButtonsInfo[buttonNo].pin])
            const buttonInfo = {
              [buttonNo]: {
                type: initButtonsInfo[buttonNo].type,
                pin: initButtonsInfo[buttonNo].pin,
                status: (buttons_status[initButtonsInfo[buttonNo].pin] ? buttons_status[initButtonsInfo[buttonNo].pin] : "0")
              }
            };
            state = {
              ...state,
              ...buttonInfo
            }
          });
          console.log("[roomControl] DATA Before saving: ", formData.room_selected, state)
          setConnected(true);
          setButtonsInfo(prevState => ({
            ...prevState,
            ...state
          }));
      };

      return () => {
        try {
          if(newClient?.disconnect){
            newClient.disconnect();
          } else {
  
          }
        } catch(er1){
          console.log({er1})
        }
      };
    } else if(!isFocused){
      // console.log("INSIDE !ISFOCUSED: ", isFocused, timeOut);
      // // clearTimeout(timeOut);
      // setConnected(undefined);
      // // setTime(undefined);
      // if(client){
      //   const topic_name = "device_" + formData.email + "_" + roomSelected + "_" + switchSelected;
      //   client.unsubscribe(topic_name);
      //   client.disconnect();
      // }
      // return () => {
      //   client.disconnect();
      // };
      // setClient(undefined);
      // setSocket(undefined);
      // if(socket && !socket.connected){
      //   socket.removeAllListeners();
      //   socket.disconnect(true);
      // }
      // if(client?.disconnect){
      //   client.disconnect();
      // }
      // if(cancelTokenSource){
      //   cancelTokenSource.cancel();
      // }
    }
      } catch(err){
        // alert("ERROR in Hooks");
        // alert(err.message);
        console.log("ERROR in Hooks", err);
        setClient(null);
        setConnected(false);
        return () => {};
      }
    }, [isFocused, isDeviceModeEnabled, roomSelected, switchSelected]);

    // console.log("HERE:", {connected, timeOut})

    const sendControlSocketMessage = (payload, ip_address, roomSelected, switchSelected) => {
      console.log("ABOUT TO SEND")
      const data = {
        ...payload,
        type: "standard",
        auth_token_verification: auth_token 
      };
      const msg = encodeJSON(data, 3);
      console.log({msg})
      let message = new Paho.Message(msg);
      message.destinationName = send_signal_channel;
      client.send(message);
      console.log("Complete SEND")
    };

    console.log({connected});

    const handleFormChange = async (name, value) => {
      const room_value = value.value;
      console.log({name, value})
      if(connected != undefined && (roomSelected != room_value) && room_value != 'null'){
        // cancelButtonSource.cancel();
        // clearTimeout(timeOut);
        setConnected(undefined);
        const resp = await AsyncStorage.getItem('user_info');
        const roomInfo = JSON.parse(resp).roomInfo;
        console.log({roomInfo})
        roomInfo[room_value]['switch_selected'] = '1'; //Note: cause we want to attach this info inside roomInfo['1'] and not roomInfo which will increase object keys
        const payload = {
          ...formData,
          [name]: parseInt(room_value),
          roomInfo: {
              ...formData.roomInfo,
              [room_value]: roomInfo[room_value],
          }
        };
        await AsyncStorage.setItem('user_info', JSON.stringify(payload));
        console.log(JSON.stringify(payload,null,2));
        setFormData(payload);
        setRoomSelected(room_value);
      }
    };

    const handleSwitchBoardChange = async(switch_board_no) => {
      const switch_board_value = switch_board_no.value;
      if(connected != undefined && ( switchSelected != switch_board_value )  && switch_board_value != 'null'){
        // cancelButtonSource.cancel();
        // clearTimeout(timeOut);
        // setConnected(undefined);
        const resp = await AsyncStorage.getItem('user_info');
        let roomInfo = JSON.parse(resp).roomInfo;
        roomInfo[roomSelected]['switch_selected'] = switch_board_value; //Note: cause we want to attach this info inside roomInfo['1'] and not roomInfo which will increase object keys
        // roomInfo[value]['switches'] = parseInt(roomInfo.switches[switch_board_no]);
        // roomInfo[value]['buttons'] = parseInt(roomInfo.switches[switch_board_no]['buttons']);
        const payload = {
          ...formData,
          roomInfo: {
            ...formData.roomInfo,
            [roomSelected]: roomInfo[roomSelected]
          }
        };
        console.log('switchINFOR=', JSON.stringify(payload, null,2));
        const t = await AsyncStorage.setItem('user_info', JSON.stringify(payload));
        console.log({t})
        setFormData(payload);
        setSwitchSelected(switch_board_value);
      }
    }
    const handleButtonClick = async (i, button_index, button) => {
      if((formData.room_selected == roomSelected) && connected){
        try {
          // if(cancelTokenSource){
          //   cancelTokenSource.cancel();
          // }
          // cancelButtonSource = axios.CancelToken.source();
          const headers = {
            'Content-Type': 'application/json',
            "Accept": "application/json"
          };
          const ip_address = formData.roomInfo[roomSelected]?.switches[`switch_board_${switchSelected}`].ip_address;
          const auth_token_verification = `sm_enterprises_${formData.email}_bbsr_module`;          
          const payload = {
            name: button.status == "1" ? "on" : "off",
            type: "one",
            button_index: `${button_index}`,
            button: `${button.pin}`,
            auth_token_verification
          };
          console.log({payload, ip_address});
          sendControlSocketMessage(payload, ip_address, roomSelected, switchSelected)
        } catch(err){
          console.log("Error in handleButtonClick: ", err);
        }
      }
    };

    const toggleDeviceModeSwitch = async() => {
      const deviceMode = isDeviceModeEnabled ? 'disabled' : 'enabled'; // Note: it's opp here as updating the state will be async so we are taking the switched value here and adding the conditions accordingly. 
      // setIsDeviceModeEnabled(previousState => !previousState);
      //Send some request here to signify the mode change
      // send it to server which will then the nodemcu will know.
      formData.roomInfo[roomSelected].switches[`switch_board_${switchSelected}`]['online_mode'] = deviceMode;
      const payload = {
        ...formData,
        roomInfo: {
            ...formData.roomInfo,
            [roomSelected]: {
              ...formData.roomInfo[roomSelected],
              switches: {
                ...formData.roomInfo[roomSelected].switches,
                [`switch_board_${switchSelected}`]: {
                  ...formData.roomInfo[roomSelected].switches[`switch_board_${switchSelected}`],
                  online_mode: deviceMode
                }
              }
            },
        }
      };
      console.log('DeviceMode change = ', JSON.stringify(payload, null,2));
      setIsDeviceModeEnabled(previousState => !previousState);
      await AsyncStorage.setItem('user_info', JSON.stringify(payload));
    } 

    // const setUserInfoInLocalStorage = async(device_mode) => {
    //   const payload = {
    //     ...formData,
    //     roomInfo: {
    //       ...formData.roomInfo,
    //       [roomSelected]: roomInfo[roomSelected]
    //     }
    //   };
    //   console.log('device toggle change = ', JSON.stringify(payload, null,2));
    //   await AsyncStorage.setItem('user_info', JSON.stringify(payload));
    // }

    // useEffect(()=>{
      
    // },[isDeviceModeEnabled])

    console.log(JSON.stringify(buttonsInfo,null,2));
    // console.log(JSON.stringify(formData,null,2))
    // console.log(JSON.stringify(formData.roomInfo[formData?.room_selected]?.switches[`switch_board_${formData?.roomInfo[formData.room_selected].switch_selected}`] ?? {},null,2))

    const light_types = ["Light", "light", "tubelight", "Tubelight", "Bulb", "Table Lamp", "table lamp", "lamp", "LIGHT", "TUBELIGHT", "TUBE LIGHT"];
    const fan_types = ["Fan", "fan", "FAN", "fAN", "FAn", "Ceiling Fan", "CEILING FAN", "ceiling fan"];
    const ac_types = ["AC", "ac", "Ac", "Air Conditioner", "AIR CONDITIONER"];
    const tv_types = ["TV", "tv", "Tv", "Television", "TELEVISION"];
    // console.log(formData.jwt_token)
    // console.log("ROOMID=", `https://smenterprisestech.in/surveillance/feed/${formData?.roomInfo[roomSelected]?.channel_id}/${formData.auth_token}`)
    // const webviewUrl = isDeviceModeEnabled ? `https://smenterprisestech.in/surveillance/surveillance/feed/${formData?.roomInfo[roomSelected]?.channel_id}` : `http://${primary_server_ip_address}:${formData.surveillance_port}/surveillance/feed/${formData?.roomInfo[roomSelected]?.channel_id}`;
    // console.log({webviewUrl})
    const rooms = Object.keys(formData.roomInfo).length > 0 ? (Object.keys(formData.roomInfo).map((room) => ({
      label:formData.roomInfo[room].type, value: room
    }))) : [];
    const room_options = [
      {label: "Pick a room", value: "null"},
      ...rooms   
    ];
    const switchBoards = Object.keys(formData?.roomInfo[roomSelected]?.switches ?? {}).length > 0 ? (Object.keys(formData?.roomInfo[roomSelected]?.switches).map((switch_board, i) => ({
      label:`Switch Board: ${(i+1)}`, value: (i+1)
    }))) : [];
    const switch_board_options = [
      {label: "Select the Switch Board", value: "null"},
      ...switchBoards   
    ];
  
    console.log({room_options})
    const customStyles = {
      control: (provided, state) => ({
        ...provided,
        minHeight: '60px',
        backgroundColor: '#f0f0f0',
        border: '2px solid #ff0000',
        borderRadius: '10px',
      }),
      valueContainer: (provided, state) => ({
        ...provided,
        height: '60px',
        padding: '0 16px',
      }),
      input: (provided, state) => ({
        ...provided,
        margin: '0px',
      }),
      indicatorSeparator: () => ({
        display: 'none',
      }),
      dropdownIndicator: (provided, state) => ({
        ...provided,
        padding: '8px',
      }),
      option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? 'white' : 'black',
        backgroundColor: state.isSelected ? '#ff0000' : 'white',
      }),
    };
    
    return (
      <div className="controller_container">
        <div className="controller_scrollView">
        <header className="controller_header_welcome">
              Controller
          </header>
          <p className="controller_welcome">
            {formData.roomInfo[roomSelected]?.type ?? ''}
          </p> 
          <div className="controller_picker">
          <Select
              value={roomSelected ?? '1'} 
              onChange={(itemValue, itemIndex) =>
                handleFormChange('room_selected', itemValue)
              }
              placeholder={roomSelected ? formData.roomInfo[roomSelected]?.type : "Pick a room"}
              style={customStyles}
              options={room_options}
              // style={styles.picker}
            />
            {/* <Select
              value={roomSelected ?? '1'} 
              onChange={(itemValue, itemIndex) =>
                  handleFormChange('room_selected', itemValue)
              }
              placeholder="Pick a room"
              style={{ color: 'black' }}
              // style={styles.picker}
            >
              <Select.Item key="start_0" label="Pick a room" value={'null'} />
            {
              Object.keys(formData.roomInfo).length > 0 && Object.keys(formData.roomInfo).map((room) => (
                  <Select.Item key={`room_${room}`} label={formData.roomInfo[room].type} value={room} />
              ))    
            }
            </Select>  */}
          </div>
          <div className="controller_pickerSwitch">
            <Select
              value={switchSelected ?? `switch_board_1`}
              onChange={(itemValue, itemIndex) =>
                  handleSwitchBoardChange(itemValue)
              }
              placeholder={switchSelected ? `Switch Board: ${switchSelected}` : "Select the Switch Board"}
              style={{ color: 'black' }}
              options={switch_board_options}
              // style={styles.picker}
            />
              {/* <Select.Item key="switch_0" label="Select the Switch Board" value={'null'} />
            {
              Object.keys(formData?.roomInfo[roomSelected]?.switches ?? {}).length > 0 && Object.keys(formData?.roomInfo[roomSelected]?.switches).map((switch_board, i) => (
                  <Select.Item key={`switch_${switch_board}`} label={`Switch Board: ${(i+1)}`} value={(i+1)} />
              ))    
            }
            </Select>  */}
          </div>
          <p 
            placeholderTextColor="black" 
            key={`switch_online_mode_text`} 
            className="controller_toggleText" > 
              { `Online Mode` } 
          </p>
          <Switch
            className="controller_toggleSwitch"
            // style={styles.toggleSwitch}
            key={`switch_online_mode`} 
            // trackColor={{ false: "#767577", true: "#81b0ff" }}
            // thumbColor={isDeviceModeEnabled ? "#f5dd4b" : "#f4f3f4"}
            // ios_backgroundColor="#3e3e3e"
            style={{color: 'gold'}}
            onChange={() => {
              toggleDeviceModeSwitch(roomSelected, switchSelected);
            }}
            value={isDeviceModeEnabled}
          />
          { (connected == true) && 
              <> 
                {/* <WebView
                  style={styles.feed}
                  source={{ 
                    uri: webviewUrl,
                    headers: { 
                      Authorization: `Bearer ${formData.jwt_token}`
                    }
                  }}
                /> */}
                    {/* <View style={styles.columnsContainer}>
                    <View style={styles.column}><Text>column1</Text></View>
                    <View style={styles.column}><Text>column2</Text></View>
                  </View> */}
                <div className="controller_controller">
                {
                    (roomSelected != 'null') && (formData.room_selected == roomSelected) &&
                    <div>
                    { Object.keys(buttonsInfo).map((button,i) => {
                      // console.log("1")
                      // console.log({button, i})
                      // console.log((i!=1) && (i%2 == 0));
                      const isLastButton = ((i+1) ==  Object.keys(buttonsInfo).length); 
                      // console.log({isLastButton})
                      if((i!=1) && (i%2 == 0)){
                        return (
                          <button
                            key={`button_${i}`}
                            style={{backgroundColor: 'transparent', border: 'none'}}
                            // style={ ((formData?.roomInfo[roomSelected]?.switches[`switch_board_${switchSelected}`]?.buttonInfo[button]?.status == "0") 
                            //       ? styles.buttonOff : styles.button)
                            // }
                            // style={{marginTop: 20}}
                            onClick={() => {
                                handleButtonClick(button, i, buttonsInfo[button])
                            }}
                          >
                          {/* <Text 
                            key={`button_text_${i}`} 
                            style={(buttonsInfo[button]?.status == "0") ? styles.buttonText: styles.buttonOffText}
                          > */}
                            { 
                              ((buttonsInfo[button]?.status == "0") && (light_types.includes(buttonsInfo[button]?.type))) && <img key={`device_logo_${i}`} className={isLastButton ? "controller_imagestyleLast" : "controller_imagestyle"} src={LightOn} />
                            }
                            { 
                              ((buttonsInfo[button]?.status == "1") && (light_types.includes(buttonsInfo[button]?.type))) && <img key={`device_logo_${i}`} className={isLastButton ? "controller_imagestyleLast" : "controller_imagestyle"} src={LightOff} />
                            }
                            { 
                              ((buttonsInfo[button]?.status == "0") && (fan_types.includes(buttonsInfo[button]?.type))) && <img key={`device_logo_${i}`} className={isLastButton ? "controller_imagestyleLast" : "controller_imagestyle"} src={FanOn} />
                            }
                            { 
                              ((buttonsInfo[button]?.status == "1") && (fan_types.includes(buttonsInfo[button]?.type))) && <img key={`device_logo_${i}`} className={isLastButton ? "controller_imagestyleLast" : "controller_imagestyle"} src={FanOff} />
                            }
                            { 
                              ((buttonsInfo[button]?.status == "0") && (ac_types.includes(buttonsInfo[button]?.type))) && <img key={`device_logo_${i}`} className={isLastButton ? "controller_imagestyleLast" : "controller_imagestyle"} src={ACOn} />
                            }
                            { 
                              ((buttonsInfo[button]?.status == "1") && (ac_types.includes(buttonsInfo[button]?.type))) && <img key={`device_logo_${i}`} className={isLastButton ? "controller_imagestyleLast" : "controller_imagestyle"} src={ACOff} />
                            }
                            { 
                              ((buttonsInfo[button]?.status == "0") && (tv_types.includes(buttonsInfo[button]?.type))) && <img key={`device_logo_${i}`} className={isLastButton ? "controller_imagestyleLast" : "controller_imagestyle"} src={TVOn} />
                            }
                            { 
                              ((buttonsInfo[button]?.status == "1") && (tv_types.includes(buttonsInfo[button]?.type))) && <img key={`device_logo_${i}`} className={isLastButton ? "controller_imagestyleLast" : "controller_imagestyle"} src={TVOff} />
                            }
                            { 
                              ((buttonsInfo[button]?.status == "0") && ((!light_types.includes(buttonsInfo[button]?.type)) && (!fan_types.includes(buttonsInfo[button]?.type)) && (!tv_types.includes(buttonsInfo[button]?.type)) && (!ac_types.includes(buttonsInfo[button]?.type)))) && <img key={`device_logo_${i}`} className={isLastButton ? "controller_imagestyleLast" : "controller_imagestyle"} src={SwitchOn} />
                            }
                            { 
                              ((buttonsInfo[button]?.status == "1") && ((!light_types.includes(buttonsInfo[button]?.type)) && (!fan_types.includes(buttonsInfo[button]?.type)) && (!tv_types.includes(buttonsInfo[button]?.type)) && (!ac_types.includes(buttonsInfo[button]?.type)))) && <img key={`device_logo_${i}`} className={isLastButton ? "controller_imagestyleLast" : "controller_imagestyle"} src={SwitchOff} />
                            }
                            {/* {formData.roomInfo[roomSelected]?.switches[`switch_board_${switchSelected}`].buttonInfo[button].value} */}
                          {/* </Text> */}
                          </button>
                        )
                      }
                    })
                    }
                    </div>
                    }
                    {
                    (roomSelected != 'null') && (formData.room_selected == roomSelected) && 
                    <div>
                    { Object.keys(buttonsInfo).map((button,i) => {
                      // console.log("2")
                      // console.log({button, i})
                      // console.log(((i==1) || (i%2 != 0)));
                      const isLastButton = ((i+1) == Object.keys(buttonsInfo).length); 
                      // console.log({isLastButton, buttonsInfo: buttonsInfo.length})

                      if((i==1) || (i%2 != 0)){
                        return (
                          <button
                            key={`button_${i}`}
                            style={{backgroundColor: 'transparent', border: 'none'}}
                            // style={ ((formData?.roomInfo[roomSelected]?.switches[`switch_board_${switchSelected}`]?.buttonInfo[button]?.status == "0") 
                            //       ? styles.buttonOff : styles.button)
                            // }
                            // style={{marginTop: 20}}
                            onClick={() => {
                                handleButtonClick(button, i, buttonsInfo[button])
                            }}
                          >
                          {/* <Text 
                            key={`button_text_${i}`} 
                            style={(buttonsInfo[button]?.status == "0") ? styles.buttonText: styles.buttonOffText}
                          > */}
                            { 
                              ((buttonsInfo[button]?.status == "0") && (light_types.includes(buttonsInfo[button]?.type))) && <img key={`device_logo_${i}`} className={isLastButton ? "controller_imagestyleLast" : "controller_imagestyle"} src={LightOn} />
                            }
                            { 
                              ((buttonsInfo[button]?.status == "1") && (light_types.includes(buttonsInfo[button]?.type))) && <img key={`device_logo_${i}`} className={isLastButton ? "controller_imagestyleLast" : "controller_imagestyle"} src={LightOff} />
                            }
                            { 
                              ((buttonsInfo[button]?.status == "0") && (fan_types.includes(buttonsInfo[button]?.type))) && <img key={`device_logo_${i}`} className={isLastButton ? "controller_imagestyleLast" : "controller_imagestyle"} src={FanOn} />
                            }
                            { 
                              ((buttonsInfo[button]?.status == "1") && (fan_types.includes(buttonsInfo[button]?.type))) && <img key={`device_logo_${i}`} className={isLastButton ? "controller_imagestyleLast" : "controller_imagestyle"} src={FanOff} />
                            }
                            { 
                              ((buttonsInfo[button]?.status == "0") && (ac_types.includes(buttonsInfo[button]?.type))) && <img key={`device_logo_${i}`} className={isLastButton ? "controller_imagestyleLast" : "controller_imagestyle"} src={ACOn} />
                            }
                            { 
                              ((buttonsInfo[button]?.status == "1") && (ac_types.includes(buttonsInfo[button]?.type))) && <img key={`device_logo_${i}`} className={isLastButton ? "controller_imagestyleLast" : "controller_imagestyle"} src={ACOff} />
                            }
                            { 
                              ((buttonsInfo[button]?.status == "0") && (tv_types.includes(buttonsInfo[button]?.type))) && <img key={`device_logo_${i}`} className={isLastButton ? "controller_imagestyleLast" : "controller_imagestyle"} src={TVOn} />
                            }
                            { 
                              ((buttonsInfo[button]?.status == "1") && (tv_types.includes(buttonsInfo[button]?.type))) && <img key={`device_logo_${i}`} className={isLastButton ? "controller_imagestyleLast" : "controller_imagestyle"} src={TVOff} />
                            }
                            { 
                              ((buttonsInfo[button]?.status == "0") && ((!light_types.includes(buttonsInfo[button]?.type)) && (!fan_types.includes(buttonsInfo[button]?.type)) && (!tv_types.includes(buttonsInfo[button]?.type)) && (!ac_types.includes(buttonsInfo[button]?.type)))) && <img key={`device_logo_${i}`} className={isLastButton ? "controller_imagestyleLast" : "controller_imagestyle"} src={SwitchOn} />
                            }
                            { 
                              ((buttonsInfo[button]?.status == "1") && ((!light_types.includes(buttonsInfo[button]?.type)) && (!fan_types.includes(buttonsInfo[button]?.type)) && (!tv_types.includes(buttonsInfo[button]?.type)) && (!ac_types.includes(buttonsInfo[button]?.type)))) && <img key={`device_logo_${i}`} className={isLastButton ? "controller_imagestyleLast" : "controller_imagestyle"} src={SwitchOff} />
                            }
                            {/* {formData.roomInfo[roomSelected]?.switches[`switch_board_${switchSelected}`].buttonInfo[button].value} */}
                          {/* </Text> */}
                          </button>
                        )
                      }
                    })
                    }
                    </div>
                    }
                </div>
              </> 
            }
            { (connected == false) && 
                <>  
                <p className="controller_connectionText">
                  Connecting...
                </p> 
                {/* <Text style={styles.connectionText}>
                  Unable to connect to {'\n'}SM Smart Switch Board. 
                  {'\n'}Kindly Contact Support.
                </Text>  */}
                <ClipLoader
                  color={"#ffffff"}
                  loading={!connected}
                  size={60}
                  style={{marginLeft: '80px'}}
                />
                {/* <View style={styles.loadingIcon}>  
                  <Icon name="refresh" size={20} color="#990"/> 
                </View> */}
              </>
            }
            { (connected == undefined) && 
                <>
                  <p className="controller_connectionText">
                    Connecting...
                  </p> 
                  <ClipLoader
                  color={"#ffffff"}
                  loading={!connected}
                  size={60}
                  style={{marginLeft: '80px'}}
                />
                </>
            }
        </div>
      </div>
    )
}

export default Controller;

