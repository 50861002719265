import * as React from 'react';
import AsyncStorage from 'localforage';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faBook, faGear, faGamepad } from '@fortawesome/free-solid-svg-icons';

import Controller from './screens/ControllerMQT';
import Register from './screens/Register';
import Home from './screens/Home';
import Settings from './screens/Settings';
import BottomTabNavigator from './BottomTabNavigator';

import { UserContext } from './context/userContext';

export default function App() {
  const userInitState = {
    fullname: '',
    contact: '',
    password: '',
    confirm_password: '',
    address: '',
    gender: '',
    email: '',
    total_rooms: '',
    roomInfo: {},
    rooms: "",
    room_selected: '1',
    // switch_selected: '1',
    logged_in: false,
  };
  const [formData, setFormData] = React.useState(userInitState);

  React.useEffect(() => {
    const fetchFromStorage = async () => {
      const resp = await AsyncStorage.getItem('user_info');
      const storedDataParsed = JSON.parse(resp);
      if(storedDataParsed){
        console.log({storedDataParsed})
        const payload = {
          ...formData,
          ...storedDataParsed
        }
        setFormData(payload);
      }
    }
    fetchFromStorage();
  },[]);
  
  const routes = [
    { logged_in: false, path: '/', label: 'Home', icon: '🏠', element: <Home formData={formData} setFormData={setFormData} userInitState={userInitState}/>},
    // { path: '/login', label: 'Login', icon: '👤' ⚙️, component: ProfileScreen },
    { logged_in: false, path: '/register', label: 'Register', icon: '👤', element: <Register formData={formData} setFormData={setFormData}/> },
    { logged_in: true, path: '/controller', label: 'Controller', icon: '🕹️', element: <Controller formData={formData} setFormData={setFormData} userInitState={userInitState}/>},
    // { path: '/login', label: 'Login', icon: '👤' ⚙️, component: ProfileScreen },
    { logged_in: true, path: '/settings', label: 'Settings', icon: '⚙️', element: <Settings formData={formData} setFormData={setFormData}/> },
  ];

  const logged_in_routes = [
    { logged_in: true, path: '/controller', label: 'Controller', icon: <FontAwesomeIcon icon={faGamepad} color='rgb(177, 151, 4)'/>, element: <Controller formData={formData} setFormData={setFormData} userInitState={userInitState}/>},
    { logged_in: true, path: '/settings', label: 'Settings', icon: <FontAwesomeIcon icon={faGear} color='rgb(177, 151, 4)'/>, element: <Settings formData={formData} setFormData={setFormData} userInitState={userInitState}/> },
  ];

  const basic_routes = [
    { logged_in: false, path: '/', label: 'Home', icon: <FontAwesomeIcon icon={faHouse} color='rgb(177, 151, 4)'/>, element: <Home formData={formData} setFormData={setFormData} userInitState={userInitState}/>},
    { logged_in: false, path: '/register', label: 'Register', icon: <FontAwesomeIcon icon={faBook} color='rgb(177, 151, 4)'/>, element: <Register formData={formData} setFormData={setFormData} userInitState={userInitState}/> },
  ];


  return (
    <>
    <UserContext.Provider value={formData}>
    <Router>
      <div style={{ paddingBottom: '60px' }}>
        <Routes>
          {
            formData?.logged_in && logged_in_routes.map((route, index) => (
              <Route key={index} exact path={route.path} element={route.element} />
            ))
          }
          {
            !formData?.logged_in && basic_routes.map((route, index) => (
              <Route key={index} exact path={route.path} element={route.element} />
            ))
          }
        </Routes>
      </div>
      <BottomTabNavigator routes={(formData?.logged_in) ? logged_in_routes : basic_routes} />
    </Router>
    </UserContext.Provider>
    {/* <div>
      <BrowserRouter>
      <nav className="navbar navbar-expand-md navbar-light sticky-top" 	role="navigation">
        <div className="container-fluid">
            <a className="navbar-brand" href="/home">SM Remote</a>
            <Nav className="ml-auto">
              <NavItem>
                <NavLink to="/login" className="nav-link">
                  Login
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/register" className="nav-link">
                  Register
                </NavLink>
              </NavItem>
            </Nav>
        </div>
      </nav>
        <Routes>
          <Route path="/login" component={<Home formData={formData} setFormData={setFormData} userInitState={userInitState}/>} />
          <Route path="/register" component={<Register formData={formData} setFormData={setFormData}/>} />
        </Routes>
      </BrowserRouter>
    </div> */}
      {/* <Tab.Navigator>
        <UserContext.Provider value={formData}>
        {
          formData.logged_in == false && 
          <>
            <Tab.Screen 
              name="Login" 
              options={{
                tabBarIcon: ({size=20, focused=true, color="red"}) => {
                  return (<FontAwesomeIcon icon="sign-in" size={20} color="#990" />)
                }
              }}
              children={ props => <Home formData={formData} setFormData={setFormData} userInitState={userInitState} {...props}/> } 
            />
            <Tab.Screen 
              name="Register"
              options={{
                tabBarIcon: ({size=20, focused=true, color="red"}) => {
                  return (<FontAwesomeIcon icon="book" size={20} color="#990" />)
                }
              }} 
              children={ props => <Register formData={formData} setFormData={setFormData} {...props} /> } 
            />
          </>   
        } 
        {
          formData.logged_in == true && 
          <>
            <Tab.Screen 
              name="Controller"
              options={{
                tabBarIcon: ({size=20, focused=true, color="red"}) => {
                  return (<FontAwesomeIcon icon="usb" size={20} color="#990" />)
                }
              }} 
              children={ props => <Controller formData={formData} setFormData={setFormData} userInitState={userInitState} navigation={props.navigation} {...props} /> } 
            />  
            <Tab.Screen 
              name="Settings"
              options={{
                tabBarIcon: ({size=20, focused=true, color="red"}) => {
                  return (<FontAwesomeIcon icon="gear" size={20} color="#990" />)
                }
              }} 
              children={ props => <Settings formData={formData} setFormData={setFormData} userInitState={userInitState} {...props} /> } 
            />  
          </>
        }   
      </UserContext.Provider>       
      </Tab.Navigator> */}
    </>
  );
}